*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

hr {
  height: 0;
  width: 100%;
  border: 1px solid $gray_light;
  margin: 15px 0;
}

html:focus-within {
  scroll-behavior: smooth;
}

html {
  background: $white;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  padding-top: 60px;
  background: $white;
  color: $darkgray;

  &.whitelabeled {
    padding-top: 0;

    .header {
      opacity: 0; 
      top: -60px;
    }

    .page {
      padding-top: 0;
    }
  }

  &.not-whitelabeled {

    .page {
      .note-content {
        margin-top: -40px;
      }
    }
  }
}

.footer-whitelabeled {
  margin-top: 80px;
}

.footer-navigation {
  margin: 20px auto;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 20px 0;

    a {
      color: $gray5;

      &:hover {
        color: $primary;
      }
    }
  }
}

.powered-by-spokenote {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  span {
    color: $gray5;
    transition: .15s linear;
  }

  &:hover {
    
    span {
      color: $primary;
    }

    #spokenote-logo-footer .logo-path {
      fill: $primary;
    }
  }
}

#spokenote-logo-footer {
  max-width: 86px;
  width: 100%;
  cursor: pointer;

  .logo-path {
    fill: $gray5;
    transition: .15s linear;
  }

  // &:hover {
  //   .logo-path {
  //     fill: $primary;
  //   }
  // }
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: block;
}

// input,
// button,
// textarea,
// select {
//   font: inherit;
// }

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.page {
  padding: 40px 20px;
  max-width: 1300px;
  margin: 0 auto;

  .page-heading {
    display: flex;
    justify-content: space-between;
  }

  .content-box {
    padding: 30px 0;
  }
}

.red {
  background: red;
}

.blue {
  background: blue;
}

.container {
  width: 100%;
  max-width: 1300px;
  padding: 0 15px;
  display: flex;
  margin: 0 auto;
}

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.p-0 {
  padding: 0;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}


.pb-140 {
  padding-bottom: 140px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pb-220 {
  padding-bottom: 220px !important;
}

.pb-240 {
  padding-bottom: 240px !important;
}

.pb-260 {
  padding-bottom: 260px !important;
}

.pb-280 {
  padding-bottom: 280px !important;
}

.pb-300 {
  padding-bottom: 300px !important;
}

.pb-320 {
  padding-bottom: 320px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-180 {
  padding-top: 100px !important;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.center-text {
  text-align: center;
}

.relative {
  position: relative;
}

.static {
  position: static;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hide {
  display: none !important;
}

.overflow-hidden {
  overflow: hidden;
}

.lazy {
  transition: opacity .5s linear .5s;
  opacity:0;

  &.loaded {
    opacity:1;
  }
}

.lazy.fast {
  transition: opacity 0s linear 0s;
  opacity:0;

  &.loaded {
    opacity:1;
  }
}

.square {
  position: relative;
  width: 100%;
  // overflow: hidden;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .square-content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.video-shape {
  position: relative;
  width: 100%;
  // overflow: hidden;

  &:after {
    content: "";
    display: block;
    padding-bottom: 56.25%; //16x9
  }

  .video-shape-content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.throbber-wrapper {
  min-width: 300px;
  min-height: 300px;
}

.throbber {
  display: inline-block;
  position: relative;
  position: absolute;
  left: 50%;
  transform: translate(-50px, 50px);
  width: 100px;
  height: 100px;
  opacity: .1;
}

.throbber div {
  position: absolute;
  border: 4px solid $primary;
  opacity: 1;
  border-radius: 12px;
  animation: throbber 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.throbber div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes throbber {
  0% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

.error404-wrapper {
  width: 100%;
  max-width: 400px;
  padding: 40px 20px;
  margin: 0 auto;
}

// Note: We are including duplicate .btn classes here for now to style <a> elements (eg: call to actions)
.btn {
  outline: 0;
  color: $white;
  background: linear-gradient(135deg, #6DC9C4 0%, #005677 100%);
  background-size: 150% auto;
  text-decoration: none !important;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 16px 24px;
  font-family: 'mazzard-semibold', sans-serif;
  font-weight: normal;
  font-style: normal;
  width: auto;
  border-radius: 50px;
  transition: all .15s linear;
  border: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  line-height: 1em;

  label,
  span {
    color: $white !important;
  }

  &[class*='btn-primary-outline'] {
    background: $white !important;
    box-shadow:inset 0 0 0 2px $gray_light;

    label,
    span {
      color: $primary !important;
    }
  }
}

.btn:visited {
  color: $white;
}

.btn:hover {
  color: $white;
  background-position: 70% !important;

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $primary;
  }
}

.btn:active{
  color: $white;
  transform: scale(.95);

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $primary;
  }
}

.btn:focus {
  color: $white;
  outline: 0;
  border: 0;
  box-shadow: none;

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $primary;
  }
}

.btn:focus:hover {
  color: $white;
  outline: 0;

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $primary;
  }
}

.btn:disabled,
.btn[disabled=disabled] {
  cursor: not-allowed;
  background: $mediumgray !important;
  color: $gray_light !important;
  
  &:active {
    transform: none;
  }
}

.termsfeed-com---nb-simple {
  width: 100% !important;
  max-width: 400px !important;
}

.cc-nb-main-container {
  padding: 20px !important;
  font-family: "mazzard",sans-serif !important;
  background: #f4f6f8 !important;
}

.cc-nb-buttons-container button,
.cc-cp-foot-button button {
  outline: 0;
  color: #fff;
  background: linear-gradient(135deg, #6DC9C4 0%, #005677 100%) !important;
  background-size: 150% auto;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 16px 24px;
  font-size: 13px;
  line-height: 17px;
  font-family: "mazzard-bold",sans-serif;
  width: auto;
  border-radius: 50px;
  transition: all .15s linear;
  text-decoration: none !important;
  border: 0;
  display: inline-flex;
  align-items: center;
  text-transform: none;
  font-size: 18px;
  line-height: 18px;
}

.cc-nb-buttons-container button.cc-nb-changep {
  background: transparent !important;
}

.link {
  font-family: "mazzard-semibold",sans-serif;
  color: $primary;
  padding: 0;
  background: transparent;

  &:hover,
  &:active,
  &:focus,
  &:focus:hover {
    color: $primary;
    text-decoration: underline;
  }
}

.react-tooltip {
  z-index: 5000 !important;
  border-radius: 6px !important;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .btn {
    margin-bottom: 0;
  }
}