.header {
  background: $white;
  height: 60px;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(0,0,0,.1);
  padding: 10px;
  position: fixed;
  z-index: 501;
  top: 0;
  left: 0;
  transition: .15s linear;

  .header-container {
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  .nav-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  #spokenote-logo-link {
    display: block;
    height: 30px;
  }

  #spokenote-logo {
    max-width: 158px;
    width: 100%;
    cursor: pointer;
    @media (min-width: 340px) {
      transform: translateX(-30px);
    }

    .logo-path {
      fill: $primary;
      transition: .15s linear;
    }

    &:hover {
      .logo-path {
        fill: $secondary;
      }
    }
  }
}

.navigation-box {
  background: $white;
  width: 100%;
  max-width: 280px;
  height: calc(100% - 60px);
  z-index: 500;
  position: fixed;
  top: 60px;
  transition: .4s ease-in-out;

  &.close {
    transform: translateX(-100%);

    @media (min-width: 960px) {
      // transform: translateX(0%);
      box-shadow: 1px 0 0 0 rgba(0,0,0,.1);
    }
  }

  &.open {
    box-shadow: 3px 0px 16px 0px rgba(0,0,0,0.1);
  }


  ul.main-nav {
    list-style: none;
    margin: 0;
    padding: 40px 20px 20px 20px;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: flex;
        gap: 10px;
        align-items: center;
        font-family: 'mazzard', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        color: $darkgray;
        transition: .15s linear;
        text-decoration: none;
        padding: 10px 15px 10px 15px;
        border-radius: 6px;
        position: relative;

        .nav-icon {
          width: 24px;

          path,
          polyline,
          line,
          rect,
          circle {
            transition: .15s linear;
            fill: none;
            stroke: $darkgray;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
        }

        &:hover,
        &.active {
          background: $tint;
          color: $primary;

          .nav-icon {
            width: 24px;
  
            path,
            polyline,
            line,
            rect,
            circle {
              stroke: $primary;
            }
          }
        }
      }
    }
  }

  ul.auth-nav {
    list-style: none;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;

    li {
      width: 50%;

      a {
        display: block;
        color: $primary;
        border: 2px solid rgba(0,0,0,.2);
        padding: 8px 16px;
        font-family: 'mazzard-semibold', sans-serif;
        font-weight: normal;
        font-style: normal;
        width: auto;
        border-radius: 50px;
        text-align: center;
        text-decoration: none;
        transition: .15s linear;

        &:hover {
          border: 2px solid rgba(0,0,0,.4);
        }
      }
    }
  }
}

#navigation-overlay {
  pointer-events: none;
  touch-action: none;
  background: rgba(245,245,245,.8);
  backdrop-filter: blur(4px);
  opacity: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 499;
  transition: opacity .5s ease-in-out;

  &.open {
    opacity: 1;
    pointer-events: all;
    touch-action: auto;
  }

  // @media (min-width: 960px) {
  //   display: none;
  // }
}

#nav-toggle {
  height: 40px;
  width: 40px;
  background: transparent !important;
  padding: 0;
  margin: 0;
  cursor: pointer;
  

  &:hover #nav-toggle-icon span {
    background: $primary;
  }

  // @media (min-width: 960px) {
  //   opacity: 0;
  //   pointer-events: none;
  //   touch-action: none;
  //   display: none;
  // }
}


#nav-toggle-icon {
  width: 30px;
  height: 24px;
  position: relative;
  background: transparent;
  padding: 0;
  margin: 0;
  transform: rotate(0deg);
  transition: .5s ease-in-out;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 30px;
    background: $mediumgray;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  
   &:nth-child(1) {
      top: 1px;
      transform-origin: left center;
    }
  
    &:nth-child(2) {
      top: 11px;
      left: 50%;
      transform: translateX(-50%);
      transform-origin: left center;
    }
  
    &:nth-child(3) {
      bottom: 0px;
      transform-origin: left center;
    }
  } 
}

.open #nav-toggle-icon span {

  &:nth-child(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 5px;
  }

  &:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  &:nth-child(3) {
    transform: rotate(-45deg);
    bottom: 0px;
    left: 5px;
  }
}